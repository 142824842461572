<template>
	
	
	<a-form-model-item  :label="datas.valueName" >
		<a-checkbox-group>
			<a-checkbox v-for="(item,index) in datas.datas">
			   {{item.name}}
			  </a-checkbox>
		</a-checkbox-group>
	</a-form-model-item>
</template>

<script>
	export default {
		props: {
			datas: {
				type: Object,
				default: function() {
					return new Object;
				}
			}
		},
		data() {
			return {
				loading: false,
				calldata: null,
			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style>
	
</style>
