<template>
	<div class="show-input-mobile-box  flex space">
		<div class="show-input-mobile-box-left">{{datas.valueName}}</div>
		<div class="show-input-mobile-box-right">
			<a-radio-group>
				<a-radio :value="item.name" v-for="(item,index) in datas.datas">
					{{item.name}}
				</a-radio>

			</a-radio-group>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			datas: {
				type: Object,
				default: function() {
					return new Object;
				}
			}
		},
		data() {
			return {
				loading: false,
				calldata: null,
			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style>
	.show-input-mobile-box {
		padding: 16px 15px;
	}

	.show-input-mobile-box-left {
		font-size: 12px;
		color: #8A8D99;
		width: 25%;
	}

	.show-input-mobile-box-right {
		font-size: 14px;
		color: #232529;
		
	}
</style>
