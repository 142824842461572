<template>
	<div class="select-range-time">
		<a-row style="line-height:40px;width:400px;">
			<a-col :span="6" style="text-align:center">
				<a-time-picker style="width:100px;" size="small"  :minuteStep="30"
					:disabledHours="getStartDisabledHours"
					:disabledMinutes="getStartDisabledMinutes" 
					@change="(val,dateStrings)=>changeTime(val,dateStrings,'startTime')" format="HH:mm" :value="startTime" valueFormat="HH:mm"/>
			</a-col>
			<a-col :span="3" style="text-align:center;">至</a-col>
			<a-col :span="6" style="text-align:center">
				<a-time-picker style="width:100px;" size="small"  :minuteStep="30"
					:disabledHours="getEndDisabledHours"
					:disabledMinutes="getEndDisabledMinutes" 
					@change="(val,dateStrings)=>changeTime(val,dateStrings,'endTime')" format="HH:mm"  :value="endTime" valueFormat="HH:mm"/>
			</a-col>
		</a-row>
	</div>

</template>

<script>
	import moment from 'moment'
	export default {
		name: 'selectRangeTime',
		props:{
			value:{
				type:Array,
				default:function(){
					return new Array
				}
			}
		},
		data() {
			return {
				startTime: null,
				endTime: null
			}
		},
		created() {
			if(this.value.length==2){
				// this.startTime=this.value[0];
				// this.endTime=this.value[1];
				this.changeTime(this.value[0],this.value[0],'startTime');
				this.changeTime(this.value[1],this.value[1],'endTime');
			}
		},
		
		methods: {
			moment,
			changeTime(val, dateStrings, type) {
				console.log(1);
				if (type === 'startTime') {
					this.startTime = dateStrings
				} else {
					this.endTime = dateStrings
				}
				if(!this.checkTime()){
					if (type === 'startTime') {
						this.startTime = null
					} else {
						this.endTime = null
					}
				}
				let value = new Array;
				value.push(this.startTime);
				value.push(this.endTime);
				this.$emit('input',value);
			},
			
			checkTime(){
				if(this.startTime==null || this.endTime==null) return true;
				let startTimeArr=this.startTime.split(':')
				let endTimeArr=this.endTime.split(':')
				if(parseInt(startTimeArr[0])>parseInt(endTimeArr[0])) return false;
				if(parseInt(startTimeArr[0]) == parseInt(endTimeArr[0])){
					if(parseInt(startTimeArr[1])>=parseInt(endTimeArr[1])){
						return false;
					}
				}
				return true;
			},
			getStartDisabledHours() {
				let hours = []
				let time = this.endTime
				
				if(time==null) return hours;
				let timeArr = time.split(':')
				for (var i = 24; i >= parseInt(timeArr[0]); i--) {
					if((timeArr[1]=='00' && i==timeArr[0]) || (i != timeArr[0])){
						hours.push(i)
					}
				}
				
				return hours
				
			},
			getStartDisabledMinutes(selectedHour) {
				let minutes = []
				let time = this.endTime
				if(time==null) return minutes;
				let timeArr = time.split(':')
				if (selectedHour == parseInt(timeArr[0])) {
					if(timeArr[1]=='00'){
						minutes.push(0)
						minutes.push(30)
					}else{
						minutes.push(30)
					}
				}
				return minutes
			},
			
			
			getEndDisabledHours() {
				let hours = []
				let time = this.startTime
				if(time==null) return hours;
				let timeArr = time.split(':')
				for (var i = 0; i <= parseInt(timeArr[0]); i++) {
					if((timeArr[1]=='30' && i==timeArr[0]) || (i!=timeArr[0])){
						hours.push(i)
					}
				}
				
				return hours
			},
			getEndDisabledMinutes(selectedHour) {
				let minutes = []
				let time = this.startTime
				if(time==null) return minutes;
				let timeArr = time.split(':')
				if (selectedHour == parseInt(timeArr[0])) {
					if(timeArr[1]=='30'){
						minutes.push(0)
						minutes.push(30)
					}else{
						
						minutes.push(0)
					}
				}
				return minutes
			},
		}
	}
</script>

<style>
</style>
